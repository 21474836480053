:root {

    /* Colors */
    --main-color: #6EB533;
    --main-color-selected: #7EC543;
    --secondary-color: #bedaff;
    --secondary-color-selected: #ceeaff;
    --secondary-color-transparent: #bedaff33;
    --main-bg-color: #f8f9fe;
    --main-bg-darker-color: #e8e9ee;
    --secondary-bg-color: #2d2d2d;
    --main-text-contrast-color: #f9fbff;
    --main-text-color: #000000;
    --main-text-color-helper: #777777;
    --main-text-color-placeholder: #777777;
    --main-text-disabled-color: #0A000000;
    --secondary-text-color: #ffffff;
    --accent-color: #6EB533;
    --error-background: #ff9999;
    --error-border: #ff6666;
    --success-background: #a2e6a2;
    --success-border: #70e670;
    --main-border: #aaaaaa;
    --dark-border: #444444;

    --text-temperature-ok: #0d6abf;
    --text-temperature-ko: #d20303;
    --background-account-active: #55AA66;
    --background-account-inactive: #666666;
    --background-account-text: #fefefe;

  
    /* Style */
    --disabled-alpha: 0.6;
    --shadow-color: #00000033;
    --rounded-component-corner-radius: 4px;
    --dashboard-module-header: 1.6rem;
  }
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiAyp8kv8JHgFVrJJLmE0tDMPKhSkFEkm8.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiAyp8kv8JHgFVrJJLmE0tMMPKhSkFEkm8.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiAyp8kv8JHgFVrJJLmE0tCMPKhSkFE.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmv1pVFteOYktMqlap.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmv1pVGdeOYktMqlap.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmv1pVF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm21lVFteOYktMqlap.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm21lVGdeOYktMqlap.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm21lVF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLucXtAOvWDSHFF.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLufntAOvWDSHFF.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLucHtAOvWDSA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmg1hVFteOYktMqlap.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmg1hVGdeOYktMqlap.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmg1hVF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmr19VFteOYktMqlap.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmr19VGdeOYktMqlap.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmr19VF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmy15VFteOYktMqlap.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmy15VGdeOYktMqlap.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmy15VF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm111VFteOYktMqlap.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm111VGdeOYktMqlap.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm111VF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm81xVFteOYktMqlap.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm81xVGdeOYktMqlap.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm81xVF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTucXtAOvWDSHFF.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTufntAOvWDSHFF.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTucHtAOvWDSA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  background-color: var(--main-bg-color);
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--main-text-color);
  background-color: var(--main-bg-color);
}

#root {
  height: 100%;
  width: 100%;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  outline: none;
  text-decoration: none;
  color: var(--accent-color);
  cursor: pointer;
}

a > span > * {
  vertical-align: middle;
}

a:link {
}

a:visited {
}

a:focus {
}

a:hover {
}

a:active {
}

a.button {
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  padding: 10px 20px;
  border-radius: var(--rounded-component-corner-radius);
  text-transform: uppercase;
  font-size: 1rem;
}

a.button:hover {
  color: var(--secondary-color-selected);
  border: 1px solid var(--secondary-color-selected);
  background-color: var(--secondary-color-transparent);
}

button {
  background-color: var(--main-color);
  color: var(--secondary-text-color);
  border: none;
  padding: 10px 20px;
  border-radius: var(--rounded-component-corner-radius);
  text-transform: uppercase;
  font-size: 1rem;
  min-height: 40px;
}

button:hover {
  cursor: pointer;
}

hr {
  margin: 30px auto;
  width: 80px!important;
  border: 0;
  border-top: 1px solid var(--secondary-color);
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 10px;
  height: 80px;
  max-height: 80px;
  padding-left: 10rem;
  box-sizing: border-box;
  background-color: var(--main-bg-color);
  z-index: 2000;
}

header > img.logo {
  max-height: 80px;
  max-width: 10rem;
  padding: 10px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
}

header > div.header-menu-item {
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
}

header > div.header-menu-item.selected {
  color: var(--accent-color);
  font-weight: 600;
}

header > .dropdown {
  display: none;
}

header .dropdown-button {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

header .dropdown-button > * {
  width: 30px;
  height: 30px;
}

header .filters {
  width: 80px;
  height: 80px;
  display: none;
  align-items: center;
  justify-content: center;
}

header .filters > * {
  width: 30px;
  height: 30px;
}

header .dropdown-content {
  position: fixed;
  right: 0;
  top: 80px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px var(--shadow-color);
  background-color: var(--main-bg-color);
}

header .dropdown-content > div.header-menu-item {
  cursor: pointer;
  padding: 10px;
  display: block;
}

header .dropdown-content > div.header-menu-item.selected {
  color: var(--accent-color);
  font-weight: 600;
}

@media screen and (min-width: 0px) and (max-width: 940px) {
  header > div.header-menu-item {
    display: none;
  }

  header > .dropdown {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  header > .filters {
    display: flex;
  }
}

@media screen and (min-height: 0px) and (max-height: 400px) {
  header .dropdown-content {
    max-height: 70%;
    overflow: auto;
  }
}

footer {
  background-color: var(--secondary-bg-color);
  height: 80px;
  max-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-text-color);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1999;
}

.login-container {
  margin: 0;
  padding: 0;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.login-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.login-logo {
  pointer-events: none;
  max-width: 50%;
  margin-bottom: 30px;
}

.login-form {
  width: 30%;
}

.change-password-form {
  width: 30%;
}

@media screen and (min-width: 450px) and (max-width: 940px) {
  .login-form {
    width: 50%;
  }
  
  .change-password-form {
    width: 50%;
  }
}

@media screen and (min-width: 0px) and (max-width: 449px) {
  .login-logo {
    max-width: 60%;
  }

  .login-form {
    width: 80%;
  }
  
  .change-password-form {
    width: 80%;
  }
}

.change-password-form input:last-of-type {
  margin-top: 8px;
}

input {
  border: 1px solid var(--main-color);
  border-radius: var(--rounded-component-corner-radius);
  padding: 5px;
}

::placeholder {
  color: var(--main-text-color-placeholder);
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: var(--main-text-color-placeholder);
}

.input-hint-icon {
  width: 1rem;
  height: 1rem;
  margin: 0;
  position: absolute;
  /* top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); */
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.input-hint-icon path {
  fill: var(--main-text-color-helper);
}

.tooltip-trigger {
  cursor: pointer;
}

.tooltip-expanded {
  font-size: 0.8rem;
  color: var(--main-text-color-helper);
  margin-top: 0 !important;
  margin-bottom: 3px !important;
  overflow: hidden;
  max-height: 300px;
  transition: max-height 0.5s ease-in;
}

.tooltip-collapsed {
  font-size: 0.8rem;
  color: var(--main-text-color-helper);
  margin-top: 0 !important;
  margin-bottom: 3px !important;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-out;
}

.form-container {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.form-container > label {
  margin-top: 10px;
}

.form-container > button {
  margin-top: 20px;
}

.form-container > a {
  margin-top: 10px;
  text-align: center;
}

.form-container > a:first-of-type {
  margin-top: 30px;
  text-align: center;
}

p.error {
  background-color: var(--error-background);
  padding: var(--rounded-component-corner-radius);
  border: 1px solid var(--error-border);
  border-radius: var(--rounded-component-corner-radius);
  text-align: center;
  font-size: 0.85rem;
}

p.success {
  background-color: var(--success-background);
  padding: var(--rounded-component-corner-radius);
  border: 1px solid var(--success-border);
  border-radius: var(--rounded-component-corner-radius);
  text-align: center;
  font-size: 0.85rem;
}

.dashboard-container {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.dashboard-container > div {
  height: 100%;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  overflow: hidden;
}

.dashboard-container > div:first-of-type {
  margin-top: 80px;
}

.dashboard-container > div:last-of-type {
  margin-bottom: 80px;
}

.companies-list {
  overflow: auto;
}

.companies-list::-webkit-scrollbar {
  display: none;
}

.filters-container {
  position: fixed;
  left: 0;
  top: 80px;
  bottom: 80px;
  width: 10rem;
  padding: 10px;
  box-sizing: border-box;
  z-index: 2000;
}

.filters-toolbar {
  display: none;
  right: 0;
  left: auto;
  bottom: auto;
  height: auto;
  max-height: calc(100vh - 140px);
  overflow: auto;
  box-shadow: 0px 8px 16px 0px var(--shadow-color);
}

.filter-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 2px;
  padding-bottom: 2px;
}

.filter-search-icon {
  width: 1.2rem;
  height: 1.2rem;
  flex: 0 0 1.2rem;
}

.filter-search > input {
  max-width: 7.5rem;
}

@media screen and (min-width: 0px) and (max-width: 940px) {
  .filters-container {
    display: none;
  }
}

.filters-container > p:first-of-type {
  margin-bottom: 0;
}

.filters-container > div {
  position: relative;
}

.filters-container .filter-name {
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 0;
}

.filters-container .filter-delete-icon {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 3px;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
}

.filters-container .filter-value {
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 0;
}

.filters-container > button {
  margin-top: 1rem;
}

.filter-dropdown-content {
  display: block;
  position: absolute !important;
  background-color: var(--main-bg-color);
  margin-top: 0.5rem;
  width: 10rem;
  overflow: scroll;
  max-height: 9.5rem;
  box-shadow: 0px 8px 16px 0px var(--shadow-color);
  left: 0;
  z-index: 10;
}

.filter-dropdown-content p {
  cursor: pointer;
  margin: 0;
  padding: 10px 12px;
  display: block;
  box-sizing: border-box;
}

.filter-dropdown-content p:hover {
  background-color: var(--main-bg-darker-color);
}

div.dashboard-content {
  padding-left: 10rem;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex-grow: 1;
  display: block;
}

@media screen and (min-width: 450px) and (max-width: 940px) {
  div.dashboard-content {
    padding-left: 0;
  }
}

@media screen and (min-width: 0px) and (max-width: 449px) {
  div.dashboard-content {
    padding-left: 0;
  }
}

.dashboard-content input::placeholder {
  color: red;
}

.dashboard-content table {
  max-height: 100%;
  display: block;
  overflow: auto;
  border-collapse: separate;
  border-spacing: 0;
}

.dashboard-content table, .dashboard-content thead, .dashboard-content tfoot {
  background-color: var(--main-bg-color);
  z-index: 1;
}

.dashboard-content thead {
  position: sticky;
  top: 0;
}

.dashboard-content tfoot {
  position: sticky;
  bottom: -1px;
}

.dashboard-content tbody {
  overflow-y: auto;
}

.dashboard-content table thead th {
  border-bottom: 1px solid var(--dark-border);
  padding: 1px 3px;
}

.dashboard-content table tfoot th {
  border-top: 1px solid var(--dark-border);
  padding: 1px 3px;
}

.dashboard-content table tbody tr td {
  border-bottom: 1px solid var(--main-border);
  padding: 1px 3px;
  vertical-align: middle;
}

.dashboard-content table tbody tr:last-child td {
  border-bottom: none !important;
}

.dashboard-content table tbody tr td img {
  display: block;
}

.dashboard-content table.clickable tbody {
  cursor: pointer;
}

.dashboard-content table.sortable thead th {
  cursor: pointer;
  position: relative;
  padding-bottom: 16px;
}

.dashboard-content table.sortable thead th svg {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.dashboard-content .status-circle {
  width: 16px;
  height: 16px;
}

.list-item-company {
  cursor: pointer;
  overflow: hidden;
  border: 1px solid var(--main-border);
  border-radius: var(--rounded-component-corner-radius);
  margin: 5px;
}

.list-item-company > div.company-logo {
  overflow: hidden;
  padding: 1rem;
}

.list-item-company > div.company-logo > img {
  width: 15rem;
  height: auto;
  max-height: 6rem;
  object-fit: contain;
}

p.dashboard-module-header {
  text-transform: uppercase;
  text-align: start;
  margin: 0;
  padding: 0px 10px;
  box-sizing: border-box;
  line-height: var(--dashboard-module-header);
  height: var(--dashboard-module-header);
  font-weight: 600;
}

.dashboard-asset-management {
  width: 100%;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
  overflow: hidden;
}

.dashboard-asset-management table {
  padding-bottom: var(--dashboard-module-header);
}

.dashboard-asset-management-top {
  height: 55%;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding-bottom: 5px;
  overflow: hidden;
}

.dashboard-asset-management-top > :first-child {
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.dashboard-asset-management-top > * {
  width: 20%;
  height: 100%;
  overflow: hidden;
}

.dashboard-asset-management-top > :last-child {
  width: 30%;
  height: 100%;
  overflow: hidden;
}

.dashboard-asset-management-top tfoot th {
  text-align: left;
}

.dashboard-asset-management-bottom {
  width: 100%;
  height: 45%;
  overflow: hidden;
}

@media screen and (min-width: 0px) and (max-width: 940px) {
  .dashboard-asset-management {
    overflow: auto;
  }

  .dashboard-asset-management-top {
    flex-wrap: wrap;
    height: auto;
  }
  
  .dashboard-asset-management-top > :first-child {
    flex: 1 1 100%;
    height: 60vh;
  }

  .dashboard-asset-management-top > :first-child table {
    max-height: 60vh;
  }

  .dashboard-asset-management-top > :first-child tbody {
    overflow: auto;
  }

  .dashboard-asset-management-top > *, .dashboard-asset-management-top > :last-child {
    flex: 1 1 45%;
    height: 400px;
  }

  .dashboard-asset-management-bottom {
    height: 60vh;
  }
}

.dashboard-asset-management .chart-sync-container {
  width: 100%;
  height: 55%;
}

.warehouse-options-container {
  text-align: start;
  float: left;
  width: 40%;
  height: 40%;
}

.warehouse-chart-container {
  float: right;
  width: 60%;
  height: 40%;
}

.sync-map-container {
  width: 100%;
  height: 100%;
}

.sync-map-container > div {
  width: 100%;
  height: 100%;
}

.leaflet-control-attribution > * {
  visibility: hidden !important;
  display: none !important;
}

.dashboard-commercial-management {
  width: 100%;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
  display:block;
}

.dashboard-commercial-management-top {
  height: 45%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.dashboard-commercial-management-top p {
  position: absolute;
  margin: 0;
  height: 1.3rem;
  left: 50%;
  transform: translateX(-50%);
}

.dashboard-commercial-management-top p:first-of-type {
  top: 0;
}

.dashboard-commercial-management-top p:last-of-type {
  top: 50%;
}

.dashboard-commercial-management-top .chart-commercial-container {
  margin-top: 1.3rem;
  height: calc(50% - 1.3rem) !important;
  width: 100% !important;
  position: absolute;
  left: 0;
}

.dashboard-commercial-management-top .chart-commercial-container:first-of-type {
  top: 0;
}

.dashboard-commercial-management-top .chart-commercial-container:last-of-type {
  top: 50%;
}

.dashboard-commercial-management-middle {
  height: 10%;
  width: 100%;
  margin: 5px 0;
  box-sizing: border-box;
  overflow: hidden;
  padding-left: 5px;
}

.dashboard-commercial-management-middle .date-inputs {
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dashboard-commercial-management-bottom {
  width: 100%;
  height: 45%;
  overflow: hidden;
  box-sizing: border-box;
}

.dashboard-commercial-management-bottom-left {
  width: 60%;
  height: 100%;
  float: left;
  padding-right: 5px;
  box-sizing: border-box;
}

.dashboard-commercial-management-bottom-right {
  width: 40%;
  height: 100%;
  float: right;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  padding-bottom: 10px;
  box-sizing: border-box;
  padding-right: 10px;
}

.dashboard-commercial-management-bottom-right > * {
  flex: 1 0 33.333%;
}

.dashboard-commercial-management-bottom table {
  padding-bottom: 5px;
}

@media screen and (min-width: 0px) and (max-width: 940px) {
  .dashboard-commercial-management {
    overflow: auto;
  }

  .dashboard-commercial-management-top {
    height: 45vh;
  }

  .dashboard-commercial-management-middle {
    height: auto;
  }

  .dashboard-commercial-management-bottom {
    flex-wrap: wrap;
    height: auto;
  }
  
  .dashboard-commercial-management-bottom-left {
    flex: 1 1 100%;
    width: 100%;
    height: 60vh;
    padding: 0;
  }

  .dashboard-commercial-management-bottom-left table {
    max-height: 60vh;
    padding-bottom: 0;
  }

  .dashboard-commercial-management-bottom-left tbody {
    overflow: auto;
  }

  .dashboard-commercial-management-bottom-right {
    flex: 1 1 100%;
    width: 100%;
    height: auto;
    padding-bottom: 0;
  }
}

.multi-range-slider *,
.multi-range-slider *::before {
  box-shadow: none !important;
}

.commercial-metric {
  border: 1px solid var(--main-border);
  border-radius: var(--rounded-component-corner-radius);
  padding: var(--rounded-component-corner-radius);
  box-sizing: border-box;
  margin: 4px 0;
  overflow: hidden;
}

.commercial-metric:first-of-type {
  margin-top: 0;
  margin-bottom: 4px;
}

.commercial-metric:last-of-type {
  margin-top: 4px;
  margin-bottom: 0;
}

.commercial-metric p {
  margin: 0;
}

.commercial-metric p:first-of-type {
  margin-top: 4px;
  font-size: 1.2rem;
}

.commercial-metric p:last-of-type {
  margin-bottom: 4px;
}

.pos-range-icon {
  width: 16px;
  height: auto;
}

.multi-range-slider .thumb .caption {
  display: none !important;
}

.dashboard-temperature {
  width: 100%;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
  display:block;
}

.dashboard-temperature-top {
  height: 45%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.dashboard-temperature-top .chart-temperature-container {
  height: calc(100% - 1.3rem) !important;
  width: 100% !important;
  position: absolute;
  left: 0;
}

.dashboard-temperature-top .chart-temperature-legend > * {
  height: 1.3rem;
  margin: 0 10px;
}

.dashboard-temperature-middle {
  height: 10%;
  width: 100%;
  margin: 5px 0;
  box-sizing: border-box;
  overflow: hidden;
  padding-left: 5px;
}

.dashboard-temperature-middle .date-inputs {
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dashboard-temperature-bottom {
  width: 100%;
  height: 45%;
  overflow: hidden;
  box-sizing: border-box;
}

.dashboard-temperature-bottom-left {
  width: 60%;
  height: 100%;
  float: left;
  padding-right: 5px;
  box-sizing: border-box;
}

.dashboard-temperature-bottom-right {
  width: 40%;
  height: 100%;
  float: right;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  padding-bottom: 10px;
  box-sizing: border-box;
  padding-right: 10px;
}

.dashboard-temperature-bottom table {
  padding-bottom: 5px;
}

@media screen and (min-width: 0px) and (max-width: 940px) {
  .dashboard-temperature {
    overflow: auto;
  }

  .dashboard-temperature-bottom {
    flex-wrap: wrap;
    height: auto;
  }
  
  .dashboard-temperature-bottom-left {
    flex: 1 1 100%;
    width: 100%;
    height: 60vh;
    padding: 0;
  }

  .dashboard-temperature-bottom-left table {
    max-height: 60vh;
    padding-bottom: 0;
  }

  .dashboard-temperature-bottom-left tbody {
    overflow: auto;
  }

  .dashboard-temperature-bottom-right {
    flex: 1 1 100%;
    width: 100%;
    height: auto;
    padding-right: 0;
    padding-bottom: 0;
  }
}

.dashboard-temperature .temperature-ok {
  color: var(--text-temperature-ok);
}

.dashboard-temperature .temperature-ko {
  color: var(--text-temperature-ko);
}

.dashboard-temperature-bottom-right-controls {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.dashboard-temperature-bottom-right-controls > * {
  flex: 0 0 auto;
}

.dashboard-temperature-bottom-right-controls :last-child {
  flex: 1 1 auto;
}

.dashboard-temperature-bottom-right-temp-avg p:first-of-type {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.dashboard-temperature-bottom-right-temp-avg p:last-of-type {
  margin-top: 0;
  margin-bottom: 0;
}

.dashboard-temperature-bottom-right-temperatures-chart {
  width: 100%;
}

.file-type-icon {
  width: 0.8rem;
  height: 0.8rem;
}

.documentation-list {
  list-style: none;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

.documentation-list > li > img {
  margin-right: 5px;
}

@media screen and (min-width: 0px) and (max-width: 940px) {
  .documentation-list {
    overflow: auto;
  }
}

.dashboard-iot-premium img {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 0px) and (max-width: 940px) {
  .dashboard-iot-premium {
    overflow: auto;
  }
}

.dashboard-admin {
  width: 100%;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
}

.dashboard-admin-left {
  height: 100%;
  width: 30%;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.dashboard-admin-left tfoot tr {
  text-align: left;
}

.dashboard-admin-right {
  height: 100%;
  width: 70%;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 0px) and (max-width: 940px) {
  .dashboard-admin {
    overflow: auto;
    flex-wrap: wrap;
  }

  .dashboard-admin-left {
    flex: 1 1 100%;
    height: 40%;
  }

  .dashboard-admin-right {
    flex: 1 1 100%;
    height: 60%;
  }
}

.dashboard-admin-events-header {
  font-size: 1.2rem;
  text-align: left;
  font-weight: 600;
}

.dashboard-admin-right tfoot tr {
  text-align: left;
}

.account-container {
  height: 100%;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.account-container > div {
  height: 100%;
  width: 100%;
  max-width: 100%;
  margin: 0;
  overflow: auto;
  margin-top: 80px;
  margin-bottom: 80px;
}

.account-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-over-card {
  font-size: 1.2rem;
  font-weight: 600;
  width: 70%;
}

.card {
  width: 70%;
  background-color: var(--main-bg-color);
  border: 1px solid var(--main-border);
  border-radius: var(--rounded-component-corner-radius);
  box-sizing: border-box;
  overflow: hidden;
  padding: 20px;
  margin: 10px 0px;
  text-align: left;
}

@media screen and (min-width: 450px) and (max-width: 940px) {
  .header-over-card {
    width: 80%;
  }
  
  .card {
    width: 80%;
  }
}

@media screen and (min-width: 0px) and (max-width: 449px) {
  .header-over-card {
    width: 90%;
  }
  
  .card {
    width: 90%;
  }
}

.card > *, .card > .company-info > * {
  margin: 10px 0 0 0;
}

.card-header {
  font-size: 1.1rem;
  margin: 0 !important;
}

.card-field-name {
  color: var(--main-text-color-helper);
}

.card-field-value {
  margin: 0 !important;
}

.card > .company-info {
  border-bottom: 1px solid var(--main-border);
}

.card > .company-info:first-child {
  padding-top: 0 !important;
}

.card > .company-info:last-child {
  padding-bottom: 0 !important;
  border-bottom: none;
}

.card > .company-info {
  padding: 10px 0;
}

.account-status-chip-icon {
  color: var(--background-account-text);
}

.account-status-chip-active, .account-status-chip-inactive {
  font-size: 0.8rem;
  color: var(--background-account-text);
  padding: 0 10px;
  border-radius: 1rem;
  vertical-align: baseline;
  white-space: nowrap;
}

.account-status-chip-active *, .account-status-chip-inactive * {
  line-height: 1rem;
  vertical-align: middle;
}

.account-status-chip-active {
  background-color: var(--background-account-active);
}

.account-status-chip-inactive {
  background-color: var(--background-account-inactive);
}

.landing-container {
  margin: 0;
  padding: 0 0 80px 0;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #000000;
}

.landing-container *:not(:first-child) {
  z-index: 1;
}

.landing-container .landing-logo {
  pointer-events: none;
  height: 5rem;
  max-width: 50%;
  margin-bottom: 60px;
}

.landing-container > h1 {
  color: #ffffff;
  font-size: 3rem;
  line-height: 2.9rem;
  font-weight: 800;
  margin: 0;
}

.landing-container > h1 > span {
  color: var(--accent-color);
}

.landing-container .vertical-separator {
  margin: 30px 0;
}

.landing-bg {
  position: absolute;
  bottom: 80px;
  width: 100%;
}

.vertical-separator {
  width: 2px;
  height: 100px;
  background-color: var(--accent-color);
}